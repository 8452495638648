import { HttpRestService } from "@/services/http/HttpRestService";
import store from "@/store/store";

export class DashboardRepository {
  static build() {
    return new DashboardRepository();
  }

  getMetaDia() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/meta-dia?unidadeId=${unidadeId}`
    );
  }

  getMetaHora() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/meta-hora?unidadeId=${unidadeId}`
    );
  }

  getConcluido() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/concluido?unidadeId=${unidadeId}`
    );
  }

  getFaturados() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/faturados?unidadeId=${unidadeId}`
    );
  }

  getAtrasados() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/atrasados?unidadeId=${unidadeId}`
    );
  }

  getPaineis() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/dashboard/paineis?unidadeId=${unidadeId}`);
  }

  getCabos() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/dashboard/cabo?unidadeId=${unidadeId}`);
  }

  getPerfis() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(`/api/dashboard/perfil?unidadeId=${unidadeId}`);
  }

  getDiversos() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/diversos?unidadeId=${unidadeId}`
    );
  }

  getConferenciaAc() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/conferencia-ac?unidadeId=${unidadeId}`
    );
  }

  getConferenciaFinal() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/conferencia-final?unidadeId=${unidadeId}`
    );
  }

  getAguardandoEmbarque() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/v2/dashboard/embarque?unidadeId=${unidadeId}`
    );
  }

  getPloomesAguardandoProducao() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/ploomes/aguardando-producao?unidadeId=${unidadeId}`
    );
  }

  getPloomesEmLinhaDeProducao() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/ploomes/linha-producao?unidadeId=${unidadeId}`
    );
  }

  getPloomesAguardandoEmbarque() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/dashboard/ploomes/aguardando-embarque?unidadeId=${unidadeId}`
    );
  }

  getInversores() {
    const unidadeId = store.state.menu.selectedFilial.value;
    return HttpRestService.get(
      `/api/v2/dashboard/pedidos-com-inversores-pendentes?unidadeId=${unidadeId}`
    );
  }
}
